/* Style For Custom Table */

.table {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-bottom: 20px;
    scrollbar-width: none;
    width: 100%;
    min-height: 250px;
}

.table-head {
    /* border-top: 1px solid var(--favGray-color); */
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    padding: 10px 0 10px 0;
    min-width: 1000px;
}

.table-head-item {
    width: 100%;
    /* border-right:1px solid #e0e0e0; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    font-size: 14px;
    color: #C3B6BE;
    font-weight: bold;
}

.table-head-item:last-child {
    border-right: 0px;
}

.table-row {
    min-height: 50px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    min-width: 1000px;
    transition: all 0.4s;
}

.table-row:hover {
    transition: all 0.43s;
    /* transform: scale(1.01); */
}

.table-row-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-left: 20px; */
    font-size: 14px;
    font-weight: bold;
    color: #563746;
    position: relative;
}

.table .delete-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: crimson;
    cursor: pointer;
    font-weight: 900;
    font-size: 18px;
}

.table .more-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--glasses--head--);
    cursor: pointer;
    font-weight: 900;
    font-size: 18px;
}

.table .edit-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fe721c;
    cursor: pointer;
    font-weight: 900;
    font-size: 18px;
}

.table-counter {
    height: 40px;
    margin-top: 20px;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}

.table-counter-main {
    height: 30px;
    display: flex;
}

.table-counter-main-item {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    background: #fff;
    cursor: pointer;
    border-radius: 10px;
    color: var(--glasses--head--);
    font-size: 14px;
    font-weight: bold;
}

.table-counter-main-item-active {
    background: var(--glasses--head--);
    color: #fff;
}

.table-play {
    width: 30px;
    height: 30px;
    background: var(--bg1-color);
    color: var(--text-sec-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-action {
    display: flex;
    flex-direction: column;
}

.table-action-list {
    position: absolute;
    width: 120px;
    background: var(--bg1-color);
    margin-left: -40px;
    margin-top: 27px;
    z-index: 10000;
    display: none;
    border-radius: 5px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, .24);
}

.table-action-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-action-list-active {
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px 0;
}

.table-action-list-item {
    width: 100%;
    display: flex;
    height: 35px;
    color: var(--text-sec-color);
    align-items: center;
    font-size: 13px;
    padding-left: 10px;
}

.table-action-list-item:hover {
    background: var(--section-color);
    color: var(--purple-color);
}

.table-action-list-item .svg-inline--fa {
    width: 12px;
    height: 12px;
}

.table-action-list-item span {
    margin-left: 10px;
}

.table-profile {
    display: flex;
    align-items: center;
}

.table-profile div {
    width: 35px;
    height: 35px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background: var(--bg1-color);
}

.table-profile span {
    margin-left: 10px;
}

.table-toggle-main {
    width: 60px;
    height: 27px;
    border: 1px solid #e0e0e0;
    border-radius: 30px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
    margin-top: -18px;
    background: #e0e0e0;
}

.table-toggle input {
    width: 0;
    height: 0;
    opacity: 0;
}

.table-toggle-main::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--glasses--head--);
    margin-top: 2px;
    left: 5px;
    transition: all 0.3s;
    right: unset;
}

.table-toggle input:checked~.table-toggle-main {
    background: #fff;
    transition: all 0.3s;
}

.table-toggle input:checked~.table-toggle-main::before {
    left: unset;
    right: 4px;
    transition: all 0.3s;
}

.table-tag {
    padding: 5px 10px;
    background: #28424b;
    color: #27c56e;
    font-weight: 600;
    border-radius: 4px;
}

.table-select {
    background: unset;
    border: 1px solid var(--purple-color);
    color: var(--text-sec-color);
    height: 30px;
    border-radius: 3px;
}

.table-select option {
    color: var(--bg2-color);
}

.table-tag {
    padding: 0 10px;
    height: 20px;
    color: var();
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-weight: 100;
}

.table-tag-1 {
    background: rgba(0, 207, 232, .12);
    color: #00cfe8;
    border: 1px solid #00cfe8;
}

.table-tag-2 {
    background: rgba(234, 84, 85, .12);
    color: #ea5455;
    border: 1px solid #ea5455;
}

.table-tag-3 {
    background: rgba(40, 199, 111, .12);
    color: #28c76f;
    border: 1px solid #28c76f;
}

.table-img {
    width: 56px;
    height: 42px;
    border-radius: 5px;
}

@media only screen and (max-width:650px) {
    .table-head-item,
    .table-row-item {
        justify-content: center;
        padding-right: 0;
    }
}