@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@600&family=Jura:wght@600&family=Montserrat:wght@300&family=Source+Sans+Pro&display=swap");

html,
div {
  font-family: "Quicksand", sans-serif;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Quicksand", sans-serif !important;
}

:root {
  --glasses--head--: #4AAAC5;
}

button {
  font-family: 'Quicksand', sans-serif !important;
}

span,
div,
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: 'Quicksand', sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: 'Quicksand', sans-serif !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  border-radius: 10px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
}

input:autofill {
  background: transparent;
  /* or any other */
}

.invoice-table td,
.invoice-table th {
  border: 1px solid #dddddd;
  padding: 0.5rem;
  font-size: 13px;
  text-align: center;
}

.ant-upload-list-item-progress {
  width: calc(95% - 24px) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:focus {
  outline: none;
}

.overflow-y-scroll::-webkit-scrollbar {
  display: none;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

/* Hide scrollbar for IE, Edge and Firefox */

.overflow-y-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Custom Bg */

.ct-bg-1 {
  background: rgba(0, 0, 0, 0) linear-gradient(0deg,
      rgb(255, 200, 4) 0%,
      rgb(255, 200, 4) 50%,
      rgb(255, 255, 255) 50%,
      rgb(255, 255, 255) 100%) repeat scroll 0% 0%;
}

/* Style For CModal (Custom modal) */

.CModal {
  left: -1000;
  width: 0%;
  height: 100%;
  position: fixed;
  background: #0000009a;
  z-index: 1000;
  transition: all 0.3s;
  /* display: none; */
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
}

.CModal-active {
  visibility: unset;
  transition: all 0.3s;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  /* bottom: 0; */
  transform: scale(1);
}

.CModal-cont {
  margin: 0 auto;
  width: 500px;
  min-height: 50px;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: #000;
  font-size: 13px;
}

.CModel-head {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.CModel-head h4 {
  font-size: 14px;
}

.CModel-Bottom {
  width: 100%;
  height: 50px;
  border-top: 1px solid #e0e0e0;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.CModel-Bottom-btn-ok {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  background: var(--glasses--head--);
  color: #fff;
  cursor: pointer;
}

.CModel-Bottom-btn-cancel {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border: 1px solid #ff3a44;
  border-radius: 3px;
  background: none;
  color: #ff3a44;
  margin-left: 10px;
  cursor: pointer;
}

.CModel-Bottom-btn-del {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  /* border:1px solid tomato; */
  border-radius: 3px;
  background: #ff3a44;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}

.CModel-Bottom-btn-info {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border: 1px solid var(--glasses--head--);
  border-radius: 3px;
  background: none;
  color: var(--glasses--head--);
  margin-left: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 550px) {
  .CModal-cont {
    width: 95%;
  }
}

/* Style For Loader btn */

.loader {
  margin: 100px auto;
  font-size: 3px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load5 {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

@keyframes load5 {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

/* Style For LoadingPage */

.LoadingPage {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #0000009a;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
}

.LoadingPage-cont {
  width: 250px;
  height: 160px;
  background: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.LoadingPage-cont span {
  color: rgb(77, 75, 75);
}

.LoadingPage .spinner-4 {
  margin-bottom: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, var(--glasses--head--)) content-box;
  -webkit-mask: repeating-conic-gradient(#0000 0deg,
      #000 1deg 20deg,
      #0000 21deg 36deg),
    radial-gradient(farthest-side,
      #0000 calc(100% - 9px),
      #000 calc(100% - 8px));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: s4 1s infinite steps(10);
}

@keyframes s4 {
  to {
    transform: rotate(1turn);
  }
}

/* Style For CkSkilton */

.CSkilton {
  width: 100%;
  height: 100%;
  background: #f3f4f6;
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  overflow: hidden;
  transition: all 0.2s;
  position: relative;
}

.CSkilton::before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 2000px;
  width: 150px;
  transform: rotate(45deg);
  background: linear-gradient(to right,
      transparent 0%,
      #f9f9f9 50%,
      transparent 100%);
  animation: skeleton-album-load 1.9s cubic-bezier(0.4, 0, 0.9, 1) infinite;
}

@keyframes skeleton-album-load {
  from {
    left: -150px;
    top: -2000px;
  }

  to {
    left: 100%;
    top: 100%;
  }
}

/* .ant-upload-list-text {
      display: none !important;
  } */

.ant-upload-select {
  width: 100%;
}

/* shop list scrollBar */

#shopsListDiv::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */

#shopsListDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px 1px rgba(128, 128, 128, 0.214);
  border-radius: 10px;
}

/* Handle */

#shopsListDiv::-webkit-scrollbar-thumb {
  background: rgba(105, 105, 105, 0.4);
  border-radius: 10px;
}

/* Handle on hover */

#shopsListDiv::-webkit-scrollbar-thumb:hover {
  background: #f0f0f0;
}

.PorductTabBar .overflow-x-scroll::-webkit-scrollbar {
  display: none;
}

/* Style For Auth */

.auth {
  width: 100%;
  min-height: 100vh;
  display: flex;
  background: #e0e0e0;
}

.ant-slider-tooltip {
  z-index: 1001 !important;
}

.auth-image {
  width: calc(100% - 500px);
  min-height: 100vh;
  background: #fdfdfd;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .auth-image img {
  max-width: 60%;
} */

.auth-main {
  width: 500px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  border-left: 1px solid #e0e0e0;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.auth-main-inner {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-main-inner form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.auth-main-inner-form-item {
  width: 100%;
}

.auth-main-inner-logo {
  max-width: 200px;
  margin: 50px 0;
}

.auth-main-inner-form-item {
  width: 100%;
  height: 60px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 20px;
  margin-bottom: 20px;
}

.auth-main-inner-form-item-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-main-inner-form-item input {
  width: calc(100% - 40px);
  width: 100%;
  height: 50px;
  border: none;
  color: #525252;
}

.auth-main-inner-form-bottom {
  display: flex;
  height: 45px;
  width: 100%;
  margin-top: 20px;
  align-items: center;
}

.auth-main-inner-form-bottom-btn {
  width: 45%;
  height: 45px;
  background: #55c6d8;
  border-radius: 30px;
  color: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-main-inner-form-bottom span {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .auth-main {
    width: 100%;
  }

  .auth-image {
    display: none;
  }

  .auth-main-inner {
    width: 100%;
  }
}

/* Style For chart-sale-select */

.chart-sale-select .ant-select-selector {
  background: #f0f8fa !important;
  border-radius: 10px !important;
  width: 130px !important;
  height: 40px;
  color: var(--glasses--head--);
  font-size: 17px;
}

.chart-sale-select .ant-select-selector:focus {
  outline: none;
  box-shadow: none;
}

.ant-select-dropdown {
  background-color: #f0f8fa !important;
  border-radius: 10px !important;
  /* font-size: 17px; */
  padding: 6px !important;
}

.ant-select-dropdown .ant-select-item {
  color: var(--glasses--head--) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fff !important;
  border-radius: 10px;
}

/* Style For Loader Address */

.addressloader-cont {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addressloader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  margin: 0 auto;
}

.addressloader:before,
.addressloader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.addressloader:before {
  animation: ball1 1s infinite;
  background-color: var(--glasses--head--);
  box-shadow: 30px 0 0 var(--glasses--head--);
  margin-bottom: 10px;
}

.addressloader:after {
  animation: ball2 1s infinite;
  background-color: var(--glasses--head--);
  box-shadow: 30px 0 0 var(--glasses--head--);
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--glasses--head--);
  }

  50% {
    box-shadow: 0 0 0 var(--glasses--head--);
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 var(--glasses--head--);
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 var(--glasses--head--);
  }

  50% {
    box-shadow: 0 0 0 var(--glasses--head--);
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 var(--glasses--head--);
    margin-top: 0;
  }
}

/* Style for add-product-as-shop-form */

.add-product-as-shop-form .ant-select-selector {
  height: 40px !important;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.add-product-as-shop-form .ant-select-selection-search {
  display: flex;
  align-items: center;
}

.add-product-as-shop-form .ant-select-selection-item,
.add-product-as-shop-form .ant-select-selection-placeholder {
  margin-top: 4px;
}

.ProfitChartCalender .ant-picker {
  border-radius: 10px;
  width: 220px;
  background: #f0f8fa;
  height: 35px;
}

.ProfitChartCalender .ant-picker input {
  color: var(--glasses--head--);
}